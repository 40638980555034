<template>
  <div class="amendments-container">
    <h1 style="margin-left: 20px;">{{$t("ID-card")}}</h1>
    <div class="idcard-con">
      <div class="con mt-2">
        <vs-input class="search-input org-width" icon-after="search" :placeholder="$t('organization-search')" v-model="oranization_search" icon="search" icon-no-border label-placeholder="icon-no-border" />
        <div class="outer-con">
          <div class="table-container" style="overflow-y: auto;">
            <table>
              <thead>
                <tr>
                  <th @click="sort('name')">{{$t("organization")}}<div class="arrow" v-if="'name' == currentSort" v-bind:class="ascending ? 'arrow_up' : 'arrow_down'"></div></th>
                </tr>
              </thead>
              <tbody>
                <tr class="cursor" v-for="organization in organizations" :key="organization.id"
                  @click="selectOrganization(organization)">
                  <td :class="{ 'active': isActiveOrganization(organization.id) }">
                    {{organization.name}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- PERSONS CONTAINER -->
      <div class="con mt-2">
        <vs-input class="search-input" icon-after="search" :placeholder="$t('name-search')" v-model="person_search"
          icon="search" icon-no-border label-placeholder="icon-no-border" />
        <div class="outer-con">
          <div v-if="selectedOrganization!=null && idCardsByOrganization.length==0" class="table-container"
            style="overflow-y: auto;">
            Keine Ausweise vorhanden
          </div>

          <div v-if="selectedOrganization!=null && idCardsByOrganization.length>0" class="table-container"
            style="overflow-y: auto;">

            <table v-if="selectedOrganization!=null">
              <thead>
                <tr>
                  <th @click="sortA('person_last_name')">{{$t("Name")}}<div class="arrow" v-if="'person_last_name' == currentSortA" v-bind:class="ascendingA ? 'arrow_up' : 'arrow_down'"></div></th>
                  <th @click="sortA('person_first_name')">{{$t("Vorname")}}<div class="arrow" v-if="'person_first_name' == currentSortA" v-bind:class="ascendingA ? 'arrow_up' : 'arrow_down'"></div></th>
                  <th @click="sortA('status')">Status<div class="arrow" v-if="'status' == currentSortA" v-bind:class="ascendingA ? 'arrow_up' : 'arrow_down'"></div></th>
                </tr>
              </thead>
              <tbody>
                <tr class="cursor" v-for="idCard in idCardsByOrganization" :key="idCard.id" @click="editIdCard(idCard)">
                  <td>{{idCard.person_last_name}}</td>
                  <td>{{idCard.person_first_name}}</td>
                  <td>
                    <vs-chip v-if="idCard.status==='active'" color="dark">
                      {{translateStatus(idCard.status)}}</vs-chip>
                    <vs-chip v-else-if="idCard.status==='error'" color="danger">
                      {{translateStatus(idCard.status)}}</vs-chip>
                    <vs-chip v-else-if="idCard.status==='deactivated'" color="danger">
                      {{translateStatus(idCard.status)}}</vs-chip>
                    <vs-chip v-else-if="idCard.status==='approved'" color="warning">
                      {{translateStatus(idCard.status)}}</vs-chip>
                    <vs-chip v-else color="grey">
                      {{translateStatus(idCard.status)}}</vs-chip>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-else>
              Keine Organisation ausgewählt
            </div>
          </div>
        </div>
      </div>
    </div>

    <vs-row vs-justify="flex-end">
      <vs-button class="organ-btn" color="primary" type="filled" icon="add" @click="addIdCard()"
        :disabled="Object.keys(selectedOrganization).length===0">
        {{$t('Ausweise anlegen')}}</vs-button>

      <vs-button class="organ-btn" color="primary" type="filled" icon="download" @click="importIdCards()"
        :disabled="Object.keys(selectedOrganization).length===0">
        {{$t('Daten importieren')}}</vs-button>
    </vs-row>


    <PopupIDcard v-if="selectedOrganization" ref="popup" /> <!-- <:organization="selectedOrganization" /> -->
    <PopupImportData v-if="selectedOrganization" ref="importPopup" /> <!-- <:organization="selectedOrganization" /> -->


    <!-- DEBUG MODE -->
    <div v-if="isDebugMode">
      <h1>selectedOrganization</h1>
      {{selectedOrganization}}

      <h1>idCardsByOrganization</h1>
      {{idCardsByOrganization}}
    </div>


  </div>
</template>

<script>
import Vue from 'vue';
import PopupIDcard from '../components/id-card/PopupIDcard.vue'
import PopupImportData from '../components/id-card/PopupImportData.vue'


export default Vue.extend({
  name: 'IdCard',
  components:{
      PopupIDcard, PopupImportData
  },
  async mounted() {
    await this.$store.dispatch('organization/loadOrganizations')
    // await this.$store.dispatch('idCard/loadIdCards')
  },

  computed: {
    idCardsByOrganization:function() {
      if (this.selectedOrganization) {
        let idCards = this.$store.getters['idCard/getIdCards']
  
        idCards = idCards.filter( o => (o.organisation_id === this.selectedOrganization.id)) 
        
        if (this.person_search !== '') {
          return idCards.filter(o => {
            return (((o.person_first_name).toLowerCase()).includes((this.person_search).toLowerCase()) || 
            ((o.person_last_name).toLowerCase()).includes((this.person_search).toLowerCase()))
          });
        }
        return idCards.sort((a,b)=> {
          let modifier=1;
          if(this.currentSortDirA === 'desc') modifier = -1;
          if(a[this.currentSortA] < b[this.currentSortA]) return -1 * modifier;
          if(a[this.currentSortA] > b[this.currentSortA]) return 1 * modifier;
          return 0;
        })
      } else {
        return null
      }
    },

    organizations:function() {
      let org = this.$store.getters["organization/getOrganizations"];
      const user = this.$store.getters['auth/getUser']
      if (!user.is_customer_admin) {
        if (user.organisation_mappings) {
            org = org.filter(u => {
                return user.organisation_mappings.find(o => {
                    if (o.organisation_id === u.id) {
                    if (o.id_card_permission) {
                        return true
                    }
                    }
                });
            });
        } else {
            return null;
        }
      }

      if (this.oranization_search !== '') {
        return org.filter(o => ((o.name).toLowerCase()).includes((this.oranization_search).toLowerCase()));
      }
      return org.sort((a,b)=> {
          let modifier=1;
          if(this.currentSortDir === 'desc') modifier = -1;
          if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
      })
    },

    organization() {
      return this.$store.getters['organization/getOrganization']
    }

  },

  data(){
    return{
      selectedOrganization: {},
      oranization_search:"",
      person_search:'',
      ascending: false,
      currentSort:'name',
      ascendingA: false,
      currentSortDir:'asc',
      currentSortA:'person_last_name',
      currentSortDirA:'asc',
    }
  },

  methods: {
      async addIdCard() {
        await this.$store.dispatch("layout/loadLayout", this.selectedOrganization.id_card_layout_id)
        const popup=this.$refs.popup ;
        popup.open();
      },
      sort:function(s) {
        if(s === this.currentSort) {
          this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
          this.ascending = !this.ascending;
        }
        this.currentSort = s;
      },
      sortA:function(s) {
        if(s === this.currentSortA) {
          this.currentSortDirA = this.currentSortDirA==='asc'?'desc':'asc';
          this.ascendingA = !this.ascendingA;
        }
        this.currentSortA = s;
      },
      async importIdCards() {
        const popup=this.$refs.importPopup ;
        popup.open(this.selectedOrganization);
      },

      async selectOrganization(organization) {
        await this.$store.dispatch("organization/loadOrganization", organization.id)
        await this.$store.dispatch('idCard/loadIdCards', { organisation_id: organization.id})
        this.selectedOrganization = this.organization
      },

      editIdCard(idCard) {
        const popup=this.$refs.popup ;
        popup.openEdit(idCard);
      },

      isActiveOrganization(organizationId) {
        if (this.selectedOrganization) {
          return organizationId === this.selectedOrganization.id
        }
        return false
      },

      translateStatus(status) {
          if (status === 'approved') {
            return "genehmigt"
          } else if (status === 'active') {
            return "aktiv"
          } else if (status === 'error') {
            return "fehlerhaft"
          } else if (status === 'open') {
            return "offen"
          } else if (status === 'activating') {
            return "aktiviert"
          } else if (status === 'deactivated') {
            return "gesperrt"
          } else if (status === 'expired') {
            return "abgelaufen"
          } else {
            return "unbekannt"
          }
      },

  }, 
});
</script>
<style>

.active {
  background-color:#a8d2f9
}




</style>
