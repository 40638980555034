<template>
    <vs-popup  title="Daten importieren" :active.sync="showPopup">
        <div class="ps-4 pe-4">
            <!-- POPUP BODY -->
            <vs-row class="content-section">
                
                <vs-col class="mb-2" vs-w="12">
                    <small>
                        {{$t("import-text")}}
                    </small>
                </vs-col>
                <vs-col class="mt-4" vs-w="12">
                    <DropFile ref="dropF" @change="onChange" @remove="onRemove" />
                    <!-- <vs-upload ref="myFile" id="file" :data="file" text="Tabelle hochladen" @change="onChange" class="w-100 mb-4" limit="1" :show-upload-button="false" single-upload /> -->
                    <!-- <vs-input type="file" ref="myFile" id="file" @change="onChange" label="Tabelle hochladen" class="w-100 mb-4" >test</vs-input> -->
                </vs-col>

                <vs-col class="mt-4" vs-w="12" v-if="file">
                    <xlsx-read :file="file" :options="{cellDates: true}">
                        <xlsx-json>
                            <template #default="{collection}">
                                <div class="table-container" style="overflow-y: auto; ">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th><vs-input type="checkbox" style="width: 25px;" :checked="selectAll" @click="selectAllCards" /></th>
                                                <th @click="sort('Nachname')">Nachname</th>
                                                <th @click="sort('Vorname')">Vorname</th>
                                                <th @click="sort('Geb.-Datum')">Geb.-Datum</th>
                                                <th v-if="layout.preconfigured_layout_id==1" @click="sort('Identifizierungs-Nr.')">Identifizierungs-Nr.</th>
                                                <th v-else @click="sort('Funktion')">Funktion</th>
                                                <th v-if="!isPreconfLayout" @click="sort('Abteilung')">Abteilung</th>
                                                <th v-else @click="sort('Gültig bis')">Gültig bis</th>
                                                <th v-if="layout.preconfigured_layout_id==2" @click="sort('Jugendflamme I')">Jugendflamme I</th>
                                                <th v-if="layout.preconfigured_layout_id==2" @click="sort('Jugendflamme II')">Jugendflamme II</th>
                                                <th v-if="layout.preconfigured_layout_id==2" @click="sort('Jugendflamme III')">Jugendflamme III</th>
                                                <th v-if="layout.preconfigured_layout_id==2" @click="sort('Leistungsspange')">Leistungsspange</th>
                                                <th v-if="layout.preconfigured_layout_id==2" @click="sort('Bundeswettbewerb')">Bundeswettbewerb</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr :class="{cursor: true, 'text-danger': !isValidRow(row) }"  
                                                v-for="row, idx in collection" :key="idx">
                                                
                                                <td v-if="isValidRow(row)" @click="addIdCard(row)">
                                                    <vs-input type="checkbox" style="width: 15px;" 
                                                        :checked="isInSelectedIdCards(row)" /></td>
                                                <td v-else>
                                                    <vs-tooltip color="danger" :text="isValidRow(row, returnErrors=true)">
                                                        <vs-chip color="danger">
                                                            Fehler
                                                        </vs-chip>
                                                    </vs-tooltip>
                                                </td>

                                                <td>{{ row['Nachname'] }}</td>
                                                <td>{{ row['Vorname'] }}</td>
                                                <td>{{ row['Geb.-Datum'] | moment("DD.MM.YYYY") }}</td>
                                                <td v-if="preconfigured_layout_id==1">{{ row['Identifizierungs-Nr.'] }}</td>
                                                <td v-else>{{ row['Funktion'] }}</td>
                                                <td v-if="!isPreconfLayout">{{ row['Abteilung'] }}</td>
                                                <td v-else>{{ row['Gültig bis'] | moment("DD.MM.YYYY") }}</td>
                                                <td v-if ="preconfigured_layout_id==2">{{ row['Jugendflamme I'] | moment("DD.MM.YYYY") }}</td>
                                                <td v-if ="preconfigured_layout_id==2">{{ row['Jugendflamme II'] | moment("DD.MM.YYYY") }}</td>
                                                <td v-if ="preconfigured_layout_id==2">{{ row['Jugendflamme III'] | moment("DD.MM.YYYY") }}</td>
                                                <td v-if ="preconfigured_layout_id==2">{{ row['Leistungsspange'] | moment("DD.MM.YYYY") }}</td>
                                                <td v-if ="preconfigured_layout_id==2">{{ row['Bundeswettbewerb'] }}</td>



                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </template>
                        </xlsx-json>
                    </xlsx-read>
                </vs-col>

            </vs-row>

      
            <!-- CONTROLS -->
            <vs-row vs-w="12" vs-justify="space-between">
                <div class="mt-4">
                    <a v-if="preconfigured_layout_id===1" class="cursor" href="/downloads/BOS-ID-Fernpiloten-Zeugnis-Vorlage.xlsx">Muster-Tabelle erzeugen</a>
                    <a v-else-if="preconfigured_layout_id===2" class="cursor" href="/downloads/BOS-ID-Jugendausweis-Vorlage.xlsx">Muster-Tabelle erzeugen</a>
                    <a v-else class="cursor" href="/downloads/BOS-ID-Ausweisvorlage.xlsx">Muster-Tabelle erzeugen</a>
                </div>

                <div>
                    <vs-button class="pop-btn" color="primary" type="filled" icon="done" 
                        @click="importIdCards()" :disabled="selectedIdCards.length===0"
                        style="max-width:170px !important"> {{$t('Importieren')}}
                    </vs-button>
                    <vs-button class="pop-btn" color="primary" type="filled" icon="close" 
                        @click="showPopup=!showPopup"> {{$t("cancel")}}</vs-button>
                </div>
            </vs-row>

        </div>

        <!-- DEBUG MODE -->
        <div v-if="isDebugMode">
            <h1>Selected ID Cards</h1>
                {{ selectedIdCards }}
            <h1>organization</h1>
                {{organization}}
            <h1>layout</h1>
                {{layout}}
            <h1>personsInOrganization</h1>
                {{personsInOrganization}}

        </div>

    </vs-popup>
</template>

<script>
import Vue from 'vue';
import { read, utils } from 'xlsx';

import XlsxRead from "vue-xlsx/dist/components/XlsxRead.js";
import XlsxJson from "vue-xlsx/dist/components/XlsxJson.js";
import XlsxSheet from "vue-xlsx/dist/components/XlsxSheet.js";
import XlsxDownload from "vue-xlsx/dist/components/XlsxDownload.js";
import XlsxWorkbook from "vue-xlsx/dist/components/XlsxWorkbook.js";

import validators from "@/plugins/inputValidators";

import DropFile from '@/plugins/dropfile/DropFile.vue';

export default Vue.extend({

    components: {
        XlsxRead,
        XlsxJson,
        XlsxDownload,
        XlsxSheet,
        XlsxWorkbook,
        DropFile
    },

    async mounted() {
        await this.$store.dispatch("customer/loadCustomerConfiguration", this.user.customer_id);
    },

    computed: {
        layout() {
            return this.$store.getters['layout/getLayout']
        },
        personsInOrganization(){
            return this.$store.getters['person/getPersons']
        },
        customerConfiguration() {
            return this.$store.getters["customer/getCustomerConfiguration"];
        },
        user() {
            return this.$store.getters["auth/getUser"];
        },
    },

    data(){
        return {
            showPopup:false,
            file: null,
            selectAll: true,
            organization: {},
            selectedIdCards: [],
            allIdCards:[],
            importBatchPerson:[],
            isPreconfLayout:false,
            ascending: false,
            currentSort:'',
            currentSortDir:'asc',
            preconfigured_layout_id:null,
        }
    },
    methods:{
        sort:function(s) {
            if(s === this.currentSort) {
            this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
            this.ascending = !this.ascending;
            }
            this.currentSort = s;
            this.collection.sort((a,b)=> {
                let modifier=1;
                if(this.currentSortDir === 'desc') modifier = -1;
                if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                return 0;
            })
        },
        async open(organization){
            this.organization = organization

            await this.$store.dispatch('layout/loadLayout', organization.id_card_layout_id)
            await this.$store.dispatch('person/loadPersonsByOrganization', organization.id)
            // this.layout = _.cloneDeep(this.$store.getters['layout/getLayout'])
            if (this.layout.preconfigured_layout_id !== null && this.layout.is_template === true) {
                this.preconfigured_layout_id = this.layout.preconfigured_layout_id
                this.isPreconfLayout=true;
            }
            else{
                this.isPreconfLayout=false;
                this.preconfigured_layout_id=null;
            }

            this.selectedIdCards = []
            this.file = null
            this.showPopup=true;
            const popup=this.$refs.dropF ;
            popup.open();
        },
        onRemove() {
            this.file = null;
            this.selectedIdCards = [];
            this.allIdCards=this.selectedIdCards;
        },
        async onChange(files) {
            this.file = files ? files[0] : null;
            // PRESELECT ALL ROWS
            const temporaryFileReader = new FileReader();
            const fileContents = await new Promise((resolve, reject) => {
                temporaryFileReader.onload = () => {
                    resolve(temporaryFileReader.result);
                };
                temporaryFileReader.readAsArrayBuffer(this.file);
            });

            var dataXlsx = new Uint8Array(fileContents);
            var workbook = read(dataXlsx, { type: 'array', cellDates: true });
            // var sheet = workbook.Sheets[workbook.SheetNames[0]];

            const data = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

            data.forEach( (row) => {
                if (this.isValidRow(row)) {
                    this.addIdCard(row)
                }
            })
            this.allIdCards=this.selectedIdCards;
        },

        isInSelectedIdCards(row) {
            return this.selectedIdCards.find( e => { 
                return (
                    (e.Email == row.Email) && 
                    (e['Handy-Nummer'] == row['Handy-Nummer'])
                )
            })
        },

        selectAllCards() {
            if (!this.selectAll) {
                this.allIdCards.forEach( (row) => {
                    if (this.isValidRow(row)) {
                        this.addIdCard(row)
                    }
                })
			} else {
                this.selectedIdCards = this.selectedIdCards.filter(e => {
                    return (
                        (e.Email == null) && (e['Handy-Nummer'] == null)
                    )
                });
            }
            this.selectAll = !this.selectAll;
        },

        addIdCard(row) {
            if (this.isInSelectedIdCards(row)) {
                this.selectedIdCards = this.selectedIdCards.filter(e => {
                    return (
                        (e.Email != row.Email) &&
                        (e['Handy-Nummer'] != row['Handy-Nummer'])
                    )
                })
            } else {
                this.selectedIdCards.push(row)
            }
        },


        getDefaultValidDate() {
            const now = new Date()
            const validUntil = new Date(now.setFullYear(now.getFullYear() + this.customerConfiguration.id_card_validity))
            return validUntil.toISOString();
        },

        isValidRow(row, returnErrors=false) {
            let errors = []
            //  Check fields are available
            if (
                    (!row.hasOwnProperty('Vorname')) ||
                    (!row.hasOwnProperty('Nachname')) ||
                    (!row.hasOwnProperty('Email')) ||
                    (!row.hasOwnProperty('Handy-Nummer')) ||
                    //(!row.hasOwnProperty('Funktion')) || -- not required
                    //(!row.hasOwnProperty('Abteilung')) || -- not required
                    (!row.hasOwnProperty('Geb.-Datum'))
                ) {
                errors += "Nicht alle Pflichtfelder wurden ausgefüllt; "
            }
            if(this.preconfigured_layout_id==1 && (!row.hasOwnProperty('Identifizierungs-Nr.'))){
                errors += "Identifizierungs-Nr. wurde nicht ausgefüllt; "
            }
            if(this.isPreconfLayout && (!row.hasOwnProperty('Gültig bis'))){
                errors += "Gültig bis Datum ist nicht vorhanden; "
            }

            // Check Phone
            if (!validators.isValidPhoneNo(row['Handy-Nummer'])) {
                errors += 'Die Handy-Nummer enthält keine Ländervorwahl (z.B. +49) oder ungültige Sonderzeichen. Sie darf nur "+", "-" und "/" als Sonderzeichen enthalten (z.B. +49151-123456789)!;'
            } 

            if (row.hasOwnProperty('Handy-Nummer')){
                const matches = this.personsInOrganization.filter( e => e.phone_number === row['Handy-Nummer'])
                if (matches.length > 0) {
                    errors += "Handy-Nummer existiert bereits; "
                }
                if(row['Handy-Nummer'].length > 15){
                    errors += "Die Handy-Nummer darf nicht mehr als 15 Zeichen enthalten;"
                }
            }

            // Check Email
            if (!validators.isValidEmail(row.Email)) {
                errors += "Email ist ungültig; "
            }

            // Check ID-Nr
            if(this.preconfigured_layout_id==1){
                if (row.hasOwnProperty('Identifizierungs-Nr.')) {
                    const matches = this.personsInOrganization.filter( e => e.id_number === row['Identifizierungs-Nr.'])
                    if (matches.length > 0) {
                        errors += "Identifizierungs-Nummer existiert bereits; "
                    }
                }
            }
            else{
                if (row.hasOwnProperty('Ausweis-Nr.')) {
                    const matches = this.personsInOrganization.filter( e => e.id_number === row['Ausweis-Nr.'])
                    if (matches.length > 0) {
                        errors += "Ausweis-Nummer existiert bereits; "
                    }
                }
            }
            

           
            // if ((!(typeof row['Geb.-Datum'] == 'string') && !(row['Geb.-Datum'] instanceof Date))) {
            //     return false
            // }

            // // Check Geb Date
            // if (!validators.isValidDate(row['Geb.-Datum'])) {
            //     return false
            // }
            if (returnErrors == true) {
                return errors
            }

            if (errors.length > 0) {
                return false
            }

            return true
        },
        
        convertStringToDate:function(dateV) {
            let parts
            if (typeof dateV == 'string') {
                parts = dateV.split('.')
            }
            if (dateV instanceof Date) {
                parts = dateV.toLocaleDateString('de-DE').split('.')
            }
            if (parts) {
                const year = parts[2];
                const month = parts[1].padStart(2, '0');
                const day = parts[0].padStart(2, '0');
                return year + '-' + month + '-' + day;
            } else {
                return null;
            }
        },

        async importIdCards() {
            this.hasImportErrors = false
            this.selectedIdCards.forEach( async e => {
                let date_of_birth = "00-00-00"
                let date_of_valid = "00-00-00"
                let extra_1 = "00-00-00"
                let extra_2 = "00-00-00"
                let extra_3 = "00-00-00"
                let extra_4 = "00-00-00"
                date_of_birth=this.convertStringToDate(e['Geb.-Datum']);
                date_of_valid=this.convertStringToDate(e['Gültig bis']);
                extra_1 = this.convertStringToDate(e['Jugendflamme I']);
                extra_2 = this.convertStringToDate(e['Jugendflamme II']);
                extra_3 = this.convertStringToDate(e['Jugendflamme III']);
                extra_4 = this.convertStringToDate(e['Leistungsspange']);

                if (!date_of_birth || !date_of_valid || !extra_1 || !extra_2 || !extra_3 || !extra_4) {
                    this.hasImportErrors = true;
                }
                // Create Person
                if(this.preconfigured_layout_id==1){

                    const person = {
                        first_name: e.Vorname,
                        last_name: e.Nachname,
                        phone_number: e['Handy-Nummer'],
                        email: e.Email,
                        role:'Fernpilot',
                        date_of_birth: date_of_birth,

                        organisation_id: this.organization.id,
                        profile_image_id: null,

                        id_number: e['Identifizierungs-Nr.'],
                        external_url: e['QR-Code (Fernpiloten-Zeugnis)'],
                        valid_until: date_of_valid
                    }
                    this.importBatchPerson.push(person);
                }
                else if(this.preconfigured_layout_id==2){
                    const person = {
                        first_name: e.Vorname,
                        last_name: e.Nachname,
                        phone_number: e['Handy-Nummer'],
                        email: e.Email,
                        date_of_birth: date_of_birth,

                        role: e['Funktion'],
                        organisation_id: this.organization.id,
                        profile_image_id: null,

                        extra_1: extra_1,
                        extra_2: extra_2,
                        extra_3: extra_3,
                        extra_4: extra_4,
                        extra_5: e['Bundeswettbewerb'],
                        valid_until: date_of_valid,
                    }
                    this.importBatchPerson.push(person);
                    }
                else{
                    const person = {
                        first_name: e.Vorname,
                        last_name: e.Nachname,
                        phone_number: e['Handy-Nummer'],
                        email: e.Email,
                        date_of_birth: date_of_birth,

                        department: e.Abteilung,
                        role: e.Funktion,
                        driver_license_class: e['Führerschein'],

                        id_number: e['Ausweis-Nr.'],
                        organisation_id: this.organization.id,
                        profile_image_id: null,

                        extra_1: e['Zusatz-1'],
                        extra_2: e['Zusatz-2'],
                        extra_3: e['Zusatz-3'],
                        extra_4: e['Zusatz-4'],
                    }
                    this.importBatchPerson.push(person);
                }
                
                

                // const personStatus = await this.$store.dispatch("person/createPerson", person);
                
                // if (personStatus != 201) {
                //     this.hasImportErrors = true
                // }

                // // Get Person
                // const p = await this.$store.getters['person/getPerson']

                // // Create ID-Card
                // const idCard = {
                //     person_id: p.id,
                //     valid_until: this.getDefaultValidDate()
                // }

                // const idCardStatus = await this.$store.dispatch("idCard/createIdCard", idCard);

                // if (idCardStatus != 201) {
                //     this.hasImportErrors = true
                // }

            });

            const batchStatus = await this.$store.dispatch("person/createBatchPerson", this.importBatchPerson);
            if (batchStatus != 207) {
                this.hasImportErrors = true
            }
            if (this.hasImportErrors) {
                this.$vs.notify({
                    color: "danger",
                    position: "bottom-right",
                    title: "Ausweise konnten nicht importiert werden!",
                });
            } else {
                this.$vs.notify({
                    color: "success",
                    position: "bottom-right",
                    title: "Ausweise wurden importiert",
                });
            }

            this.showPopup = false;
        }
        
    }

});
</script>


<style >
</style>
