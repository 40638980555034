<template>
  <vs-popup
    class="w-100"
    :title="title"
    :active.sync="showPopup"
    @close="closePopup"
    v-if="layout.stylesheet"
  >
    <vs-row vs-w="12">
      <vs-col vs-w="9">
        <vs-row class="ps-3 " v-if="!isEdit">
          <vs-checkbox
            v-model="isExistingPerson"
            @click="isExistingPerson = !isExistingPerson"
          >
            <span>Existierende Person verwenden?</span>
          </vs-checkbox>
        </vs-row>
        <vs-row class="mt-2 mb-2 " v-if="isExistingPerson">
          <vs-input
            class="search-input mt-5"
            style="width: 100% !important;"
            icon-after="search"
            @click="isPersonSelected = false"
            :placeholder="$t('Person suchen')"
            v-model="person_search"
            icon="search"
            icon-no-border
            label-placeholder="icon-no-border"
          />

          <h6>{{ titleSearch }}</h6>
          <strong class="ms-2"
            >{{ person.last_name }} {{ person.first_name }}</strong
          >

          <div
            class="table-container-search w-100"
            :style="{ height: isPersonSelected ? '5px' : '400px' }"
          >
            <table>
              <tr
                v-for="person in searchResults"
                :key="person.id"
                @click="useExistingPerson(person)"
              >
                <td>{{ person.last_name }}, {{ person.first_name }}</td>
              </tr>
            </table>
          </div>
        </vs-row>
      </vs-col>

      <!-- FIRST COL -->
      <vs-col vs-w="5" class="p-3">
        <vs-row class="mt-2 mb-2">
          <vs-row class="mt-2 mb-2">
            <vs-input
              :class="[isLockedIdCard ? lockedCard : '', activeCard]"
              :label-placeholder="$t('first-name') + '*'"
              v-model="person.first_name"
              :disabled="isExistingPerson || isLockedIdCard"
            />
          </vs-row>
          <vs-row class="mt-2 mb-2">
            <vs-input
              :class="[isLockedIdCard ? lockedCard : '', activeCard]"
              :label-placeholder="$t('last-name') + '*'"
              v-model="person.last_name"
              :disabled="isExistingPerson || isLockedIdCard"
            />
          </vs-row>
          <vs-row class="mt-2 mb-2">
            <vs-input
              :class="[isLockedIdCard ? lockedCard : '', activeCard]"
              :label-placeholder="$t('Email') + '*'"
              v-model="person.email"
              :disabled="isExistingPerson || isLockedIdCard"
            />
          </vs-row>
          <vs-row class="mt-2 mb-2">
            <vs-input
              :class="[isLockedIdCard ? lockedCard : '', activeCardS]"
              :label-placeholder="$t('Handy-Nummer') + '*'"
              v-model="person.phone_number"
              :disabled="isExistingPerson || isLockedIdCard"
            />
          </vs-row>
          <vs-row class="mt-2 mb-2">
            <vs-input
              type="date"
              class="inputx spec-input"
              :disabled="isExistingPerson || isLockedIdCard"
              :class="[isLockedIdCard ? lockedCardS : '', activeCardS]"
              :label="$t('date-birth') + '*'"
              v-model="person.date_of_birth"
            />
          </vs-row>
          <vs-row v-if="preconfigured_layout_id == 2" class="mt-1 mb-2">
            <vs-select
              :label="'Funktion'"
              :class="[isLockedIdCard ? lockedCard : '', activeCard]"
              v-model="person.role"
              :disabled="isExistingPerson || isLockedIdCard"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in function_select"
              />
              <!-- Weitere Optionen hier hinzufügen -->
            </vs-select>
          </vs-row>
          <vs-row v-else class="mt-2 mb-2">
            <vs-input
              :class="[
                isLockedIdCard || isPreconfLayout ? lockedCard : '',
                activeCard,
              ]"
              :label-placeholder="$t('function')"
              v-model="person.role"
              :disabled="isExistingPerson || isLockedIdCard || isPreconfLayout"
            />
          </vs-row>
          <vs-row
            v-if="
              this.preconfigured_layout_id == 1 &&
                !(isExistingPerson || isLockedIdCard)
            "
            class="mt-2 mb-2"
          >
            <div class="preview-container mt-3" v-if="files.length">
              <div
                v-for="file in files"
                :key="file.file_name"
                class="preview-card"
              >
                <div>
                  <img class="preview-img" src="/icons/document.svg" />
                  <p>{{ file.file_name }}</p>
                </div>
                <div v-if="isEdit && idCard.status !== 'approved'">
                  <vs-button
                    color="danger"
                    type="filled"
                    icon="delete"
                    readonly
                    @click="deleteConfirmDoc(file.id)"
                  >
                  </vs-button>
                </div>
              </div>
            </div>
            <div v-else>
              <vs-button
                class="mt-2 mb-2"
                style="width: 130%;"
                color="primary"
                type="border"
                icon-after
                icon="upload"
                @click="onPickFile"
                >Fernpiloten-Zeugnis (PDF) hochladen</vs-button
              >
              <input
                type="file"
                style="display: none"
                ref="fileInput"
                accept="application/pdf"
                @change="onFilePicked"
              />
            </div>
          </vs-row>

          <vs-row
            class="mt-2 mb-2"
            v-if="hasExtraField('driver_license_class')"
          >
            <vs-input
              :class="[isLockedIdCard ? lockedCard : '', activeCard]"
              :label-placeholder="$t('driver-license')"
              v-model="person.driver_license_class"
              :disabled="isExistingPerson || isLockedIdCard"
            />
          </vs-row>
          <vs-row class="mt-2 mb-2" v-if="hasExtraField('department')">
            <vs-input
              :class="[isLockedIdCard ? lockedCard : '', activeCard]"
              :label-placeholder="$t('department')"
              v-model="person.department"
              :disabled="isExistingPerson || isLockedIdCard"
            />
          </vs-row>

          <!-- EXTRA FIELDS -->
          <vs-row class="mt-2 mb-2" v-if="preconfigured_layout_id == 2">
            <vs-input
              type="date"
              :disabled="isLockedIdCard || isExistingPerson"
              :class="[
                isLockedIdCard || this.preconfigured_layout_id == 1
                  ? lockedCardS
                  : '',
                activeCardS,
              ]"
              :label="$t('valid-until') + '*'"
              :label-placeholder="layout.extra_1_caption"
              v-model="person.extra_1"
            />
          </vs-row>
          <vs-row class="mt-2 mb-2" v-else-if="hasExtraField('extra_1')">
            <vs-input
              v-if="!layout.extra_1_single_value"
              :class="[isLockedIdCard ? lockedCardS : '', activeCardS]"
              :label-placeholder="layout.extra_1_caption"
              v-model="person.extra_1"
              :disabled="isExistingPerson || isLockedIdCard"
            />
            <vs-select
              v-if="layout.extra_1_single_value"
              :label="layout.extra_1_caption"
              :class="[isLockedIdCard ? lockedCard : '', activeCard]"
              v-model="person.extra_1"
              :disabled="isExistingPerson || isLockedIdCard"
            >
              <vs-select-item
                v-for="(e, idx) in layout.extra_1_values"
                :key="idx"
                :value="e"
                :text="e"
              />
            </vs-select>
          </vs-row>
          <vs-row class="mt-2 mb-2" v-if="preconfigured_layout_id == 2">
            <vs-input
              type="date"
              :disabled="isLockedIdCard || isExistingPerson"
              :class="[
                isLockedIdCard || this.preconfigured_layout_id == 1
                  ? lockedCardS
                  : '',
                activeCardS,
              ]"
              :label="$t('valid-until') + '*'"
              :label-placeholder="layout.extra_2_caption"
              v-model="person.extra_2"
            />
          </vs-row>
          <vs-row class="mt-2 mb-2" v-else-if="hasExtraField('extra_2')">
            <vs-input
              v-if="!layout.extra_2_single_value"
              :class="[isLockedIdCard ? lockedCardS : '', activeCardS]"
              :label-placeholder="layout.extra_2_caption"
              v-model="person.extra_2"
              :disabled="isExistingPerson || isLockedIdCard"
            />
            <vs-select
              v-if="layout.extra_2_single_value"
              :label="layout.extra_2_caption"
              :class="[isLockedIdCard ? lockedCard : '', activeCard]"
              v-model="person.extra_2"
              :disabled="isExistingPerson || isLockedIdCard"
            >
              <vs-select-item
                v-for="(value, idx) in layout.extra_2_values"
                :key="idx"
                :value="value"
                :text="value"
              />
            </vs-select>
          </vs-row>
          <vs-row class="mt-2 mb-2" v-if="preconfigured_layout_id == 2">
            <vs-input
              type="date"
              :disabled="isLockedIdCard || isExistingPerson"
              :class="[
                isLockedIdCard || this.preconfigured_layout_id == 1
                  ? lockedCardS
                  : '',
                activeCardS,
              ]"
              :label="$t('valid-until') + '*'"
              :label-placeholder="layout.extra_3_caption"
              v-model="person.extra_3"
            />
          </vs-row>
          <vs-row class="mt-2 mb-2" v-else-if="hasExtraField('extra_3')">
            <vs-input
              v-if="!layout.extra_3_single_value"
              :class="[isLockedIdCard ? lockedCardS : '', activeCardS]"
              :label-placeholder="layout.extra_3_caption"
              v-model="person.extra_3"
              :disabled="isExistingPerson || isLockedIdCard"
            />
            <vs-select
              v-if="layout.extra_3_single_value"
              :label="layout.extra_3_caption"
              :class="[isLockedIdCard ? lockedCard : '', activeCard]"
              v-model="person.extra_3"
              :disabled="isExistingPerson || isLockedIdCard"
            >
              <vs-select-item
                v-for="(value, idx) in layout.extra_3_values"
                :key="idx"
                :value="value"
                :text="value"
              />
            </vs-select>
          </vs-row>
          <vs-row class="mt-2 mb-2" v-if="preconfigured_layout_id == 2">
            <vs-input
              type="date"
              :disabled="isLockedIdCard || isExistingPerson"
              :class="[
                isLockedIdCard || this.preconfigured_layout_id == 1
                  ? lockedCardS
                  : '',
                activeCardS,
              ]"
              :label="$t('valid-until') + '*'"
              :label-placeholder="layout.extra_4_caption"
              v-model="person.extra_4"
            />
          </vs-row>
          <vs-row class="mt-2 mb-2" v-else-if="hasExtraField('extra_4')">
            <vs-input
              v-if="!layout.extra_4_single_value"
              :class="[isLockedIdCard ? lockedCardS : '', activeCardS]"
              :label-placeholder="layout.extra_4_caption"
              v-model="person.extra_4"
              :disabled="isExistingPerson || isLockedIdCard"
            />
            <vs-select
              v-if="layout.extra_4_single_value"
              :label="layout.extra_4_caption"
              :class="[isLockedIdCard ? lockedCard : '', activeCard]"
              v-model="person.extra_4"
              :disabled="isExistingPerson || isLockedIdCard"
            >
              <vs-select-item
                v-for="(value, idx) in layout.extra_4_values"
                :key="idx"
                :value="value"
                :text="value"
              />
            </vs-select>
          </vs-row>
          <vs-row class="mt-2 mb-2" v-if="hasExtraField('extra_5')">
            <vs-input
              v-if="!layout.extra_5_single_value"
              :class="[isLockedIdCard ? lockedCardS : '', activeCardS]"
              :label-placeholder="layout.extra_5_caption"
              v-model="person.extra_5"
              :disabled="isExistingPerson || isLockedIdCard"
            />
            <vs-select
              v-if="layout.extra_5_single_value"
              :label="layout.extra_5_caption"
              :class="[isLockedIdCard ? lockedCard : '', activeCard]"
              v-model="person.extra_5"
              :disabled="isExistingPerson || isLockedIdCard"
            >
              <vs-select-item
                v-for="(value, idx) in layout.extra_5_values"
                :key="idx"
                :value="value"
                :text="value"
              />
            </vs-select>
          </vs-row>
          <vs-row class="mt-2 mb-2" v-if="hasExtraField('extra_6')">
            <vs-input
              v-if="!layout.extra_6_single_value"
              :class="[isLockedIdCard ? lockedCardS : '', activeCardS]"
              :label-placeholder="layout.extra_6_caption"
              v-model="person.extra_6"
              :disabled="isExistingPerson || isLockedIdCard"
            />
            <vs-select
              v-if="layout.extra_6_single_value"
              :label="layout.extra_6_caption"
              :class="[isLockedIdCard ? lockedCard : '', activeCard]"
              v-model="person.extra_6"
              :disabled="isExistingPerson || isLockedIdCard"
            >
              <vs-select-item
                v-for="(value, idx) in layout.extra_6_values"
                :key="idx"
                :value="value"
                :text="value"
              />
            </vs-select>
          </vs-row>
        </vs-row>
      </vs-col>

      <!-- SECOND COL -->

      <vs-col vs-w="4">
        <div class="upload-img">
          <!-- <div class="img-container">
                  <input type="file" id="imgupload" style="display: none;" @change="uploadProfileImage" />
                  <label for="imgupload">
                    <img v-if="profileImage" :src="'data:image/png;base64, '+ profileImage.encoded_image_data" />
                    <img v-else src="/icons/upload-photo.svg" alt="" />
                  </label>
                </div> -->

          <!-- WITH CROP -->
          <div class="img-container mt-5">
            <img
              @click="
                isExistingPerson || isLockedIdCard ? !show : (show = !show)
              "
              v-if="profileImage"
              class="passport-img"
              :src="'data:image/png;base64, ' + profileImage.encoded_image_data"
            />

            <img
              @click="
                isExistingPerson || isLockedIdCard ? !show : (show = !show)
              "
              v-else
              src="/icons/upload-photo.svg"
            />
          </div>
        </div>
      </vs-col>

      <!-- THIRD COL -->
      <vs-col vs-w="3" class="p-3">
        <vs-row class="mt-3 mb-2">
          <vs-input
            class="inputx simple-input readonly"
            :label="$t('status')"
            :label-placeholder="$t('status')"
            v-model="idCardStatus"
            disabled
          />
        </vs-row>
        <vs-row class="mt-3 mb-2">
          <vs-input
            type="date"
            class="inputx spec-input readonly"
            :label="$t('created-at')"
            :label-placeholder="$t('created-at')"
            v-model="idCard.created_at"
            disabled
          />
        </vs-row>
        <vs-row class="mt-3 mb-2">
          <vs-input
            type="date"
            class="inputx spec-input readonly"
            :label="$t('approved-at')"
            :label-placeholder="$t('approved-at')"
            v-model="idCard.approved_at"
            disabled
          />
        </vs-row>
        <vs-row class="mt-3 mb-2">
          <vs-input
            type="date"
            :disabled="
              isLockedIdCard ||
                this.preconfigured_layout_id == 1 ||
                isExistingPerson
            "
            :class="[
              isLockedIdCard || this.preconfigured_layout_id == 1
                ? lockedCardS
                : '',
              activeCardS,
            ]"
            :label="$t('valid-until') + '*'"
            :label-placeholder="$t('valid-until') + '*'"
            v-model="idCard.valid_until"
          />
        </vs-row>
        <vs-row class="mt-3 mb-2" v-if="idCard.deactivated_at">
          <vs-input
            :class="[isLockedIdCard ? lockedCardS : '', activeCardS]"
            :label="$t('locked-at')"
            :label-placeholder="$t('locked-at')"
            :value="idCard.deactivated_at | moment('DD.MM.YYYY')"
            disabled
          />
        </vs-row>
        <vs-row v-if="preconfigured_layout_id == null" class="mt-3 mb-2">
          <vs-input
            v-if="idCard.status == 'active' || isLockedIdCard"
            class="inputx spec-input readonly"
            :label-placeholder="
              isPreconfLayout
                ? $t('Identifik No.') + '*'
                : $t('ID card No.') + '*'
            "
            v-model="person.id_number"
            disabled
          />
          <vs-input
            v-else
            class="inputx spec-input"
            :label="
              isPreconfLayout
                ? $t('Identifik No.') + '*'
                : $t('ID card No.') + '*'
            "
            :label-placeholder="
              isPreconfLayout
                ? $t('Identifik No.') + '*'
                : $t('ID card No.') + '*'
            "
            v-model="person.id_number"
            :disabled="isLockedIdCard || isExistingPerson"
          />
        </vs-row>
        <vs-row v-else-if="preconfigured_layout_id == 2" class="mt-3 mb-2">
          <vs-input
            :class="lockedCardS"
            :label="$t('ID card No.') + '*'"
            :label-placeholder="$t('ID card No.') + '*'"
            v-model="person.id_number"
            disabled
          />
        </vs-row>
        <vs-row v-else class="mt-3 mb-2">
          <vs-input
            :class="lockedCardS"
            :label="
              isPreconfLayout
                ? $t('Identifik No.') + '*'
                : $t('ID card No.') + '*'
            "
            :label-placeholder="
              isPreconfLayout
                ? $t('Identifik No.') + '*'
                : $t('ID card No.') + '*'
            "
            v-model="person.id_number"
            disabled
          />
        </vs-row>
      </vs-col>
    </vs-row>

    <!-- CONTROLS -->
    <vs-row vs-type="flex" vs-justify="space-around">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-offset="0.01"
        vs-align="center"
        vs-w="5"
      >
        <vs-button
          v-if="
            isEdit &&
              ['approved', 'activating', 'active', 'expired'].includes(
                idCard.status
              )
          "
          class="pop-btn organ-btn"
          color="danger"
          type="filled"
          icon="https"
          @click="deactivateConfirm()"
        >
          {{ $t("Sperren") }}
        </vs-button>
        <vs-button
          v-if="isEdit"
          class="pop-btn organ-btn"
          color="danger"
          type="filled"
          icon="delete"
          readonly
          @click="deleteConfirm()"
        >
          {{ $t("delete") }}
        </vs-button>
        <vs-button
          v-if="isEdit && idCard.status == 'approved'"
          class="pop-btn organ-btn"
          icon="mail"
          color="primary"
          type="filled"
          readonly
          @click="resetConfirm()"
        >
          {{ $t("Aktivierung neu") }}
        </vs-button>
        <vs-button
          v-if="idCard.status == 'open'"
          class="pop-btn organ-btn"
          color="primary"
          type="filled"
          icon="done"
          @click="approveIdCard()"
        >
          {{ $t("Genehmigen") }}
        </vs-button>
        <vs-button
          v-if="isEdit && idCard.status == 'error'"
          class="pop-btn organ-btn"
          color="warning"
          type="filled"
          icon="history"
          @click="resetIdCard()"
        >
          {{ $t("Zurücksetzen") }}
        </vs-button>
        <vs-button
          v-if="isEdit && idCard.status == 'activating'"
          class="pop-btn organ-btn"
          color="warning"
          type="filled"
          icon="history"
          @click="resetIdCard()"
        >
          {{ $t("Zurücksetzen") }}
        </vs-button>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="right"
        vs-offset="1.4"
        vs-align="center"
        vs-w="5"
      >
        <vs-button
          v-if="isEdit && !isLockedIdCard"
          class="pop-btn"
          color="primary"
          type="filled"
          icon="done"
          @click="editIdCard()"
        >
          {{ $t("save") }}</vs-button
        >
        <vs-button
          v-if="!isEdit"
          class="pop-btn"
          color="primary"
          type="filled"
          icon="done"
          @click="addIdCard()"
        >
          {{ $t("create") }}</vs-button
        >
        <vs-button
          class="pop-btn"
          color="primary"
          type="filled"
          icon="close"
          @click="closePopup()"
        >
          {{ $t("cancel") }}</vs-button
        >
      </vs-col>
    </vs-row>

    <cropUpload
      v-show="show"
      field="img"
      @crop-success="cropSuccess"
      @crop-upload-success="cropUploadSuccess"
      @crop-upload-fail="cropUploadFail"
      @crop-delete="cropDelete"
      v-model="show"
      :width="300"
      :height="400"
      :langExt="langExt"
      noCircle
      img-format="png"
      ref="uploader"
    >
    </cropUpload>

    <!-- DEBUG MODE -->
    <div v-if="isDebugMode">
      <h1>PERSON</h1>
      {{ person }}

      <h1>IDCARD</h1>
      {{ idCard }}

      <h1>ORGA</h1>
      {{ organization }}

      <h1>LAYOUT</h1>
      {{ layout }}
    </div>
  </vs-popup>
</template>

<script>
  import Vue from "vue";
  import _ from "lodash";
  import moment from "moment";
  import CropUpload from "@/plugins/mpcropupload/upload2.vue";
  import validators from "@/plugins/inputValidators";
  import errorNotifier from "@/plugins/errorNotifier";

  export default Vue.extend({
    // props:[ 'organization' ],

    components: {
      CropUpload,
    },

    async mounted() {
      await this.$store.dispatch("customer/loadCustomerConfiguration", this.user.customer_id);

      this.initObjs();
      // await this.$store.dispatch('customer/loadCustomer', this.user.customer_id)
      this.$refs.created_at.value = "2024-02-27";
    },

    data() {
      return {
        isPersonSelected: false,
        originalTitle: 'Bitte Person wählen:',
        titleSearch: 'Bitte Person wählen: ',
        activeCard: 'inputx simple-input',
        lockedCard: 'inputx simple-input readonly',
        activeCardS: 'inputx spec-input',
        lockedCardS: 'inputx spec-input readonly',
        lockedText: 'readonly-text',
        activeText: 'active-text',
        showPopup: false,
        isEdit: false,
        isExistingPerson: false,
        isLockedIdCard: false,
        acceptDeleteConfirm: false,
        isApprovedIdCard: false,
        isPreconfLayout:false,
        preconfigured_layout_id:null,
        function_select:[
        {text: 'Jugendfeuerwehr', value: 'Jugendfeuerwehr'},
        {text: 'Kindergruppe', value: 'Kindergruppe'},
        {text: 'Betreuende', value: 'Betreuende'},
        ],

        person_search: "",

        person: {},
        idCard: {},
        files: [],
        selectedFile:null,
        deleteFileId:[],
        uploadedFileId:[],

        // CROP/UPLOAD
        show: false,
        params: {
          token: "123456798",
          name: "avatar",
        },
        headers: {
          smail: "*_~",
        },
        imgDataUrl: "",

        langExt: {
          titel: "Passfoto bearbeiten",
          hint: "Klicken um Foto auszuwählen oder Foto direkt hierher ablegen",
          loading: "Wird hochgeladen...",
          noSupported: "Ihr Browser wird nicht unterstützt. Bitte verwenden Sie IE10+ oder einen anderen Browser.",
          uploadMessage: "Mit dem Upload bestätigen Sie, im Besitz der Nutzungsrechte für das Foto zu sein.",
          uploadImgSize: "[Nur JPG-, PNG- oder SVG-Datei mit 300 x 400 Pixel und max. 1 MB Dateigröße]",
          success: "Erfolgreich hochgeladen",
          fail: "Hochladen fehlgeschlagen",
          preview: "Vorschau",
          btn: {
            off: "Abbrechen",
            close: "Schliessen",
            back: "Zurück",
            save: "Speichern",
            delete: "Löschen",
          },
          error: {
            onlyImg: "Nur Passfoto im Format: JPG,JPEG,PNG,GIF,SVG oder BMP",
            outOfSize: "Passfoto zu groß, Maximum: ",
            lowestPx: "Passfoto zu klein, Minimum: ",
          },
        },
      };
    },

    computed: {
      inputXDyn: function() {
          return isLockedIdCard ? 'inputx simple-input readonly' : 'inputx simple-input';
      },
      title() {
        if (this.isEdit && this.idCard.activated_at === null) {
          return "Ausweis bearbeiten"  + ` > ` + this.organization.name
        } else if (this.isEdit) {
          return "Ausweis bearbeiten"  + ` > ` + this.organization.name
        }
        return "Ausweis anlegen"  + ` > ` + this.organization.name
      },
      idCardStatus() {
        if (this.idCard.status === 'approved') {
          return "genehmigt"
        } else if (this.idCard.status === 'active') {
          return "aktiv"
        } else if (this.idCard.status === 'error') {
          return "fehlerhaft"
        }  else if (this.idCard.status === 'open') {
          return "offen"
        }  else if (this.idCard.status === 'activating') {
          return "aktiviert"
        }  else if (this.idCard.status === 'deactivated') {
          return "gesperrt"
        }  else if (this.idCard.status === 'expired') {
          return "abgelaufen"
        } else {
          return "unbekannt"
        }
      },
      user() {
        return this.$store.getters["auth/getUser"];
      },
      customer() {
        return this.$store.getters["customer/getCustomer"];
      },
      organization() {
        return this.$store.getters["organization/getOrganization"];
      },
      layout() {
        return this.$store.getters["layout/getLayout"];
      },
      profileImage() {
        if(this.person.profile_image_id) {
          return this.$store.getters["core/getImage"];
        } else {
          return false;
        }
      },
      customerConfiguration() {
        return this.$store.getters["customer/getCustomerConfiguration"];
      },
      searchResults() {
        // let persons = this.$store.getters["person/getPersonsByOrganization"];
        let persons = this.$store.getters["person/getPersons"]
        if (this.person_search !== "") {
          return persons.filter((o) => {
            const name = o.first_name.toLowerCase() + " " + o.last_name.toLowerCase()
            if ((name.toLowerCase()).includes(this.person_search.toLowerCase())) {
              return true
            }
          });
        }
      }
    },

    watch: {
      person: {
        deep: true,
        handler() {
          if (this.person.profile_image_id !== null) {
            this.$store.dispatch("core/loadImage", this.person.profile_image_id);
            this.$refs.uploader.setStep(1);
          }
        },
      },
    },

    methods: {
      onClosePopup() {
      this.titleSearch = this.originalTitle;
      this.person_search = '';
      // Weitere Logik beim Schließen des Popups...
    },
      closePopup() {
      this.showPopup = false; // Schließe das Popup
      this.isPersonSelected = false;
      this.onClosePopup(); // Rufe die Methode zum Zurücksetzen des Titels auf
    },
      initObjs() {
        this.person = {
          id_number: null,

          first_name: "",
          last_name: "",
          phone_number: "",
          email: "",
          date_of_birth: "",

          department: "",
          role: "",
          driver_license_class: "",

          id_number: "",
          organisation_id: this.organization.id,
          profile_image_id: null,
          external_url:null,
          extra_1: null,
          extra_2: null,
          extra_3: null,
          extra_4: null,
          extra_5: null,
          extra_6: null,
        }

        this.files=[]

        this.idCard = {
          person_id: null,
          valid_until: null,
          approved_at: null,
          created_at: new Date(),
        }
      },
      onPickFile () {
        this.$refs.fileInput.click()
      },
      async onFilePicked (event) {
        const files = event.target.files
        this.selectedFile = files[0];
        if (this.selectedFile) {
          const maxSize = 5 * 1024 * 1024; // 5MB
          if (this.selectedFile.size > maxSize) {
            errorNotifier('File size exceeds the 5MB limit. Please choose a smaller file.')
            return
          }
          await this.$store.dispatch("person/loadPerson", this.person.id);
          let file = await this.$store.dispatch("person/createFile",this.selectedFile)
          if (file) {
            this.person.external_url=file.qr_code_data;
            this.getDroneCertificate(file.qr_code_data);
            let status = await this.$store.dispatch("person/createPersonFile", file.id)
            if (status === 201) {
              await this.$store.dispatch("person/loadPersonFiles", this.person.id);
              let files = this.$store.getters["person/getFiles"];
              if (this.deleteFileId.length > 0)  {
                this.deleteFileId.forEach( async (IdFile) => {
                files = files.filter(e => e.id != IdFile)
              });
              }
              this.files=files;

              this.$vs.notify({
                color: "success",
                position: "bottom-right",
                title: "Datei wurde hochgeladen",
              });
            } else {
              errorNotifier(status)
            }
          } else {
            errorNotifier(file)
          }
        }
      },
      async getDroneCertificate(link){
        let cert= await this.$store.dispatch("person/getRemotepilot",link.replace('https://exam.lba-openuav.de/#/droneExam/certificateVerification/',''));
        if (cert) {
          let date_of_valid = new Date(cert.expirationDate).toISOString();
          this.idCard.valid_until=date_of_valid.split("T")[0];
          this.person.id_number=cert.identificationCode;
        }
      },
      convertStringToDate:function(dateV) {
          let parts
          if (typeof dateV == 'string') {
              parts = dateV.split('.')
          }
          if (dateV instanceof Date) {
              parts = dateV.toLocaleDateString('de-DE').split('.')
          }
          if (parts) {
              return parts[2] + '-' + parts[1] + '-' + parts[0];
          } else {
              return null;
          }
      },
      uploadProfileImage(event) {
        var reader = new FileReader();
        var baseString;

        reader.addEventListener(
          "loadend",
          () => {
            baseString = reader.result;
            baseString = baseString.split(",")[1];

            this.$store.dispatch("core/createImage", baseString).then((resp) => (this.person.profile_image_id = resp.data.id));
          },
          false
        );

        reader.readAsDataURL(event.target.files[0]);
      },

      async open() {
        this.initObjs();
        this.isExistingPerson = false
        this.isEdit = false;
        this.isApprovedIdCard = false;
        this.isLockedIdCard = false;
        this.isPersonSelected = false;

        this.$store.commit("core/setImage", null);
        this.$refs.uploader.setStep(1);
        await this.$store.dispatch('person/loadPersonsByOrganization', this.organization.id)
        await this.$store.dispatch('layout/loadLayout', this.organization.id_card_layout_id)
        const layout = this.$store.getters["layout/getLayout"];
        this.preconfigured_layout_id = layout.preconfigured_layout_id;
        if (layout.preconfigured_layout_id !== null && layout.is_template === true) {
          this.isPreconfLayout=true;
        }
        else {
          this.isPreconfLayout=false;
        }
        if(this.preconfigured_layout_id == 1){
          this.person.role='Fernpilot';
        }
        this.showPopup = true;
        const currentDate = new Date().toISOString().slice(0, 10);
        this.idCard.created_at= currentDate;
        const now = new Date()
        const validUntil = new Date(now.setFullYear(now.getFullYear() + this.customerConfiguration.id_card_validity))
        this.idCard.valid_until = validUntil.toISOString().slice(0, 10);

      },

      async openEdit(idCard) {
        this.isExistingPerson = false
        this.isApprovedIdCard = false;
        this.isLockedIdCard = false

        if (idCard.status == 'deactivated') {
          this.isLockedIdCard = true
        }

        await this.$store.dispatch("person/loadPerson", idCard.person_id);
        await this.$store.dispatch("person/loadPersonFiles", idCard.person_id);
        await this.$store.dispatch("organization/loadOrganization", idCard.organisation_id);
        await this.$store.dispatch('person/loadPersonsByOrganization', idCard.organisation_id)
        await this.$store.dispatch('layout/loadLayout', this.organization.id_card_layout_id)

        const layout = this.$store.getters["layout/getLayout"];
        this.preconfigured_layout_id = layout.preconfigured_layout_id;
        if (this.layout.preconfigured_layout_id !== null && this.layout.is_template === true) {
                this.preconfigured_layout_id = this.layout.preconfigured_layout_id
                this.isPreconfLayout=true;
            }
            else{
                this.isPreconfLayout=false;
                this.preconfigured_layout_id=null;
            }

        let person = this.$store.getters["person/getPerson"];

        if (person.profile_image_id !== null) {
          await this.$store.dispatch("core/loadImage");
        } else {
          this.$store.commit("core/setImage", null);
          this.$refs.uploader.setStep(1);
        }

        let files = this.$store.getters["person/getFiles"];
        this.files=files;

        // await this.$store.dispatch('idCard/loadIdCardByPersonId', person.id)
        this.showPopup = true;
        this.isEdit = true;

        this.person = _.cloneDeep(person);
        this.person.date_of_birth = this.person.date_of_birth.split("T")[0];

        // this.idCard = _.cloneDeep(this.personIdCard)
        this.idCard = _.cloneDeep(idCard);

        if (!(this.idCard.activated_at === null)) {
          this.idCard.activated_at = this.idCard.activated_at.split("T")[0];
        }
        if (!(this.idCard.approved_at === null)) {
          this.idCard.approved_at = this.idCard.approved_at.split("T")[0];
          this.isApprovedIdCard = true;
        }

        if (!(this.idCard.valid_until === null)) {
          this.idCard.valid_until = this.idCard.valid_until.split("T")[0];
        }
        if (!(this.idCard.created_at === null)) {
          this.idCard.created_at = this.idCard.created_at.split("T")[0];
        }
      },

      hasExtraField(no) {
        return this.layout.stylesheet.fields_back.find(f => f.key == no)
      },

      async addIdCard() {
        if (! await this.validateInputsAdd()) {
          return;
        }

        // Only Create new Person if not an existing was used
        if (!this.isExistingPerson) {
          if (this.preconfigured_layout_id == 1) {
            this.person.id_number='Nicht vorhanden';
          }
          let status = await this.$store.dispatch("person/createPerson", this.person);

          if (status != 201) {
            errorNotifier(status)
            return
          }

          this.person = await this.$store.getters['person/getPerson']
        }

        this.idCard.person_id = this.person.id
        this.idCard.valid_until = this.getDefaultValidDate()

        let status = await this.$store.dispatch("idCard/createIdCard", this.idCard)
        if (status === 201) {
          this.$vs.notify({
            color: "success",
            position: "bottom-right",
            title: "Ausweis wurde erstellt",
          });
        } else {
          errorNotifier(status)
        }


        this.showPopup = false;
      },

      deactivateConfirm() {
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Sperrung bestätigen`,
          text: "Wollen Sie  den aktuellen Ausweis wirklich unwiderruflich sperren?",
          accept: this.deactivateIdCard,
          acceptText: "Sperren",
          cancelText: "Abbrechen"
        });
      },

      deleteConfirm() {
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Löschung bestätigen`,
          text: "Wollen Sie den aktuellen Ausweis wirklich unwiderruflich löschen?",
          accept: this.deleteIdCard,
          acceptText: "Löschen",
          cancelText: "Abbrechen"
        });
      },
      deleteConfirmDoc(fileId) {
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Löschung bestätigen`,
          text:"Wollen Sie das Fernpiloten-Zeugnis wirklich unwiderruflich löschen?",
          accept: () => { this.deleteDoc(fileId); },
          acceptText: "Löschen",
          cancelText: "Abbrechen"
        });
      },
      resetConfirm() {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: `Aktivierungs-Email`,
          text: "Wollen Sie die Aktivierungs-Email nochmals neu versenden?",
          accept: this.sendActivationIdCard,
          acceptText: "Senden",
          cancelText: "Abbrechen"
        });
      },
      async deleteIdCard() {
        let status = await this.$store.dispatch("idCard/deleteIdCard", this.idCard);
        if (status === 200) {
          this.$vs.notify({
            color: "success",
            position: "bottom-right",
            title: "Ausweis wurde gelöscht",
          });
        } else {
          errorNotifier(status)
        }
        this.showPopup = false;
      },

      async deleteDoc(fileId) {
        // const index = this.files.findIndex(file => file.id === fileId);
        //     if (index !== -1) {
        //       // Entferne die Datei aus der Liste, bevor sie endgültig gelöscht wird
        //       this.files.splice(index, 1);
        //     }
        this.files = this.files.filter(e => e.id != fileId);

        this.deleteFileId.push(fileId);
        this.person.id_number="Nicht vorhanden";
        const now = new Date()
        const validUntil = new Date(now.setFullYear(now.getFullYear() + this.customerConfiguration.id_card_validity))
        this.idCard.valid_until = validUntil.toISOString().slice(0, 10);
        this.person.external_url=null;

        this.$vs.notify({
          color: "success",
          position: "bottom-right",
          title: "Datei wurde gelöscht",
        });

      },

      async editIdCard() {
        try{
        if (! await this.validateInputsEdit()) {
          return;
        }
        if (this.deleteFileId.length > 0) {
          this.deleteFileId.forEach( async (IdFile) => {
            let statusDel = await this.$store.dispatch("person/deleteFile", IdFile);
          if (statusDel === 200) {
            await this.$store.dispatch("person/loadPersonFiles", this.person.id);
            let files = this.$store.getters["person/getFiles"];
            this.files=files;
          } else {
            errorNotifier(statusDel)
          }
          });this.deleteFileId = []

        }
        // this.idCard.activated_at = this.idCard.activated_at + "T00:00:00.000Z"
        // this.idCard.valid_until = this.idCard.valid_until + "T00:00:00.000Z"
        // this.idCard.created_at = this.idCard.created_at + "T00:00:00.000Z"
        let status = await this.$store.dispatch("person/updatePerson", this.person);
        if (status != 200) {
          errorNotifier(status)
          return;
        }

        if (this.idCard.status !== 'active') {
        status = await this.$store.dispatch("idCard/updateIdCard", this.idCard), true;
        if (status === 200) {
          this.$vs.notify({
            color: "success",
            position: "bottom-right",
            title: "Ausweis wurde gespeichert",
          });
        } else {
          errorNotifier(status)
        }
      } else {
      // Erfolgsmeldung für Personendaten
      this.$vs.notify({
        color: "success",
        position: "bottom-right",
        title: "Ausweis wurde gespeichert",
      });
    }
  } catch (error) {
    errorNotifier(error);
  } finally {
    this.showPopup = false;
  }
    },



      async validateInputsEdit() {
        if(this.preconfigured_layout_id == 2){
         // Check Date extra_1
         const currentDate = new Date();
        const inputDate = new Date(this.person.extra_1);
        if (inputDate > currentDate) {
            this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Ungültiges Datum",
            text: 'Das Datum für die Jugendflamme I darf nicht in der Zukunft liegen!',
            });
            return false;
          }
        // Check Date extra_2
        const currentDate2 = new Date();
        const inputDate2 = new Date(this.person.extra_2);
        if (inputDate2 > currentDate2) {
            this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Ungültiges Datum",
            text: 'Das Datum für die Jugendflamme II darf nicht in der Zukunft liegen!',
            });
            return false;
          }
          // Check Date extra_3
        const currentDate3 = new Date();
        const inputDate3 = new Date(this.person.extra_3);
        if (inputDate3 > currentDate3) {
            this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Ungültiges Datum",
            text: 'Das Datum für die Jugendflamme III darf nicht in der Zukunft liegen!',
            });
            return false;
          }
          // Check Date extra_4
        const currentDate4 = new Date();
        const inputDate4 = new Date(this.person.extra_4);
        if (inputDate4 > currentDate4) {
            this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Ungültiges Datum",
            text: 'Das Datum für die Leistungsspange darf nicht in der Zukunft liegen!',
            });
            return false;
          }
        }
        // Check Approved at vs valid_until
        let approved_at = false
        let valid_until = false

        if (this.idCard.approved_at) {
          approved_at = new Date(this.idCard.approved_at)
        }
        if (this.idCard.valid_until) {
          valid_until = new Date(this.idCard.valid_until)
        }

        if (approved_at && valid_until) {
          if (approved_at > valid_until) {
            this.$vs.notify({
              color: "danger",
              position: "bottom-right",
              title: "Ungültiges Datum",
              text: "Das 'Genehmigt am' Datum muss vor dem 'Gültig bis' Datum liegen!",
            });
            return false;
          }
        }

        // Check Valid Until Date
        if (valid_until) {
          const now = new Date()
          if (valid_until < now) {
            this.$vs.notify({
              color: "danger",
              position: "bottom-right",
              title: "Ungültiges Datum",
              text: "Das 'Gültig bis' Datum muss nach dem aktuellen Datum liegen!",
            });
            return false;
          }
        } else {
          this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Fehlendes Datum",
            text: "Das 'Gültig bis' muss ausgefüllt sein!",
          });
          return false;
        }


        // Check Phone
        if (!validators.isValidPhoneNo(this.person.phone_number)) {
          this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Ungültige Handy-Nummer",
            text: 'Die Handy-Nummer enthält keine Ländervorwahl (z.B. +49) oder ungültige Sonderzeichen. Sie darf nur "+", "-" und "/" als Sonderzeichen enthalten (z.B. +49151-123456789)!',
          });
          return false
        }
        else {
          await this.$store.dispatch('person/loadPersonsByOrganization', this.organization.id);
          const persons = this.$store.getters["person/getPersons"];
          const matches = persons.filter( e => (e.phone_number === (this.person.phone_number)) && (e.id != this.person.id));
          if (matches.length > 0) {
            this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Ungültige Handy-Nummer",
            text: "Die Handy-Nummer existiert bereits!",
            });
            return false;
          }
          if((this.person.phone_number).length > 15){
            this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Ungültige Handy-Nummer",
            text: "Die Handy-Nummer darf nicht mehr als 15 Zeichen enthalten!",
            });
            return false;
          }
        }

                //Check Date of Birth
                const currentDate = new Date();
        const inputBirth = new Date(this.person.date_of_birth);
        if (inputBirth > currentDate) {
            this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Ungültiges Datum",
            text: 'Das Geburtsdatum darf nicht in der Zukunft liegen!',
            });
            return false;
          }

        // Check Email
        if (!validators.isValidEmail(this.person.email)) {
          this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Ungültige Email-Adresse",
            text: "Die Email-Adresse ist ungültig!",
          });
          return false;
        }

        //  Check First Name
        if (this.person.first_name.trim() === '') {
          this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Bitte den Vornamen eingeben!",
            text: "",
          });
          return false;
        }

        if (this.person.last_name.trim() === '') {
          this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Bitte den Nachnamen eingeben!",
            text: "",
          });
          return false;
        }


        if (this.person.date_of_birth.trim() === '') {
          this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Bitte das Geburtsdatum eingeben!",
            text: "",
          });
          return false;
        }
        return true;
      },

      async validateInputsAdd() {
        if(this.preconfigured_layout_id == 2){
        // Check Date extra_1
        const currentDate = new Date();
        const inputDate = new Date(this.person.extra_1);
        if (inputDate > currentDate) {
            this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Ungültiges Datum",
            text: 'Das Datum für die Jugendflamme I darf nicht in der Zukunft liegen!',
            });
            return false;
          }
        // Check Date extra_2
        const currentDate2 = new Date();
        const inputDate2 = new Date(this.person.extra_2);
        if (inputDate2 > currentDate2) {
            this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Ungültiges Datum",
            text: 'Das Datum für die Jugendflamme II darf nicht in der Zukunft liegen!',
            });
            return false;
          }
          // Check Date extra_3
        const currentDate3 = new Date();
        const inputDate3 = new Date(this.person.extra_3);
        if (inputDate3 > currentDate3) {
            this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Ungültiges Datum",
            text: 'Das Datum für die Jugendflamme III darf nicht in der Zukunft liegen!',
            });
            return false;
          }
          // Check Date extra_4
        const currentDate4 = new Date();
        const inputDate4 = new Date(this.person.extra_4);
        if (inputDate4 > currentDate4) {
            this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Ungültiges Datum",
            text: 'Das Datum für die Leistungsspange darf nicht in der Zukunft liegen!',
            });
            return false;
          }
        }
        // Check Approved at vs valid_until
        let approved_at = false
        let valid_until = false

        if (this.idCard.approved_at) {
          approved_at = new Date(this.idCard.approved_at)
        }
        // Check Phone
        if (!validators.isValidPhoneNo(this.person.phone_number)) {
          this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Ungültige Handy-Nummer",
            text: 'Die Handy-Nummer enthält keine Ländervorwahl (z.B. +49) oder ungültige Sonderzeichen. Sie darf nur "+", "-" und "/" als Sonderzeichen enthalten (z.B. +49151-123456789)!',
          });
          return false
        }
        else {
          await this.$store.dispatch('person/loadPersonsByOrganization', this.organization.id);
          const persons = this.$store.getters["person/getPersons"];
          const matches = persons.filter( e => (e.phone_number === (this.person.phone_number)) && (e.id != this.person.id));
          if (matches.length > 0) {
            this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Ungültige Handy-Nummer",
            text: "Die Handy-Nummer existiert bereits!",
            });
            return false;
          }
          if((this.person.phone_number).length > 15){
            this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Ungültige Handy-Nummer",
            text: "Die Handy-Nummer darf nicht mehr als 15 Zeichen enthalten!",
            });
            return false;
          }
        }

        //Check Date of Birth
        const currentDate = new Date();
        const inputBirth = new Date(this.person.date_of_birth);
        if (inputBirth > currentDate) {
            this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Ungültiges Datum",
            text: 'Das Geburtsdatum darf nicht in der Zukunft liegen!',
            });
            return false;
          }

        // Check Email
        if (!validators.isValidEmail(this.person.email)) {
          this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Ungültige Email-Adresse",
            text: "Die Email-Adresse ist ungültig!",
          });
          return false;
        }

        //  Check First Name
        if (this.person.first_name.trim() === '') {
          this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Bitte den Vornamen eingeben!",
            text: "",
          });
          return false;
        }

        if (this.person.last_name.trim() === '') {
          this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Bitte den Nachnamen eingeben!",
            text: "",
          });
          return false;
        }


        if (this.person.date_of_birth.trim() === '') {
          this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Bitte das Geburtsdatum eingeben!",
            text: "",
          });
          return false;
        }
        return true;
      },

      getDefaultValidDate() {
        const now = new Date()
        const validUntil = new Date(now.setFullYear(now.getFullYear() + this.customerConfiguration.id_card_validity))
        return validUntil.toISOString();
      },

      cropSuccess(imgDataUrl, field) {
        this.imgDataUrl = imgDataUrl;
        let baseString = imgDataUrl.split(",")[1];

        this.$store.dispatch("core/createImage", baseString).then((resp) => (this.person.profile_image_id = resp.data.id));
        this.$refs.uploader.setStep(1);
      },
      async cropDelete() {
        if (this.person.profile_image_id) {
          let status = await this.$store.dispatch('core/deleteImage', this.person.profile_image_id)
          if (status != 200) {
            errorNotifier(status)
            return
          }
          this.person.profile_image_id  = null;
          this.$refs.uploader.setStep(1);
        }
      },
      cropUploadSuccess(jsonData, field) {
      },

      cropUploadFail(status, field) {
        this.person.profile_image_id = null;
        this.$refs.uploader.setStep(1);
      },

      useExistingPerson(person) {
        this.isPersonSelected = true;
        this.titleSearch = 'Gewählte Person: '
        this.isExistingPerson = true
        this.person_search = ''
        this.idCard.person_id = person.id
        this.person = _.cloneDeep(person)
        this.person.date_of_birth = this.person.date_of_birth.split("T")[0];
      },

      async approveIdCard() {

        if (! await this.validateInputsEdit()) {
          return;
        }
            //  Check ID Number
          if (!this.person.external_url && (this.preconfigured_layout_id == 1) ) {
            this.$vs.notify({
              color: "danger",
              position: "bottom-right",
              title: "Fernpiloten-Zeugnis wurde noch nicht hochgeladen!",
              text: "",
            });
            return;
          }

          if ( (!this.idCard.valid_until || new Date(this.idCard.valid_until) < new Date() ) && (this.preconfigured_layout_id == 1)) {
            this.$vs.notify({
              color: "danger",
              position: "bottom-right",
              title: "Das Gültig bis-Datum ist ungültig oder abgelaufen!",
              text: "",
            });
            return;
          }

        let status = await this.$store.dispatch("person/updatePerson", this.person);
        if (status != 200) {
          errorNotifier(status)
          return
        }
        status = await this.$store.dispatch("idCard/updateIdCard", this.idCard, false);
        if (status != 200) {
          errorNotifier(status)
          return
        }
        status = await this.$store.dispatch("idCard/approveIdCard", this.idCard, true)
        if (status === 200) {
          this.$vs.notify({
            color: "success",
            position: "bottom-right",
            title: "Ausweis wurde genehmigt",
          });
        } else {
          errorNotifier(status)
        }
        this.showPopup = false;
      },
      async sendActivationIdCard() {
        let status = await this.$store.dispatch("idCard/resetIdCard", this.idCard)
        if (status === 200) {
          this.$vs.notify({
            color: "success",
            position: "bottom-right",
            title: "Aktivierungs-Email wurde gesendet",
          });
        } else {
          errorNotifier(status)
        }
        this.showPopup = false;
      },
      async resetIdCard() {
        let status = await this.$store.dispatch('idCard/resetIdCard', this.idCard)
        if (status === 200) {
          this.$vs.notify({
            color: "success",
            position: "bottom-right",
            title: "Ausweis wurde zurückgesetzt",
          });
        } else {
          errorNotifier(status)
        }
        this.showPopup = false;
      },
      async deactivateIdCard() {
        let status = await this.$store.dispatch('idCard/deactivateIdCard', this.idCard)
        if (status === 200) {
          this.$vs.notify({
            color: "success",
            position: "bottom-right",
            title: "Ausweis wurde gesperrt",
          });
        } else {
          errorNotifier(status)
        }
      this.showPopup = false;
   },

  },
});
</script>

<style>
.upload-img {
  flex: 1 1 180px;
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
}
.upload-img .img-container {
  padding: 5px;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  height: auto;
  width: auto;
  margin: 20px;
  border: 3px solid #cccccc;
}
.readonly-text textarea {
  color: grey;
  font-size: 15px;
}
.readonly-text {
  background-color: rgb(236, 236, 236) !important;
  color: grey !important;
}
.active-text {
  background-color: white !important;
  color: black !important;
}
.active-text textarea {
  color: black !important;
  font-size: 15px;
}

.passport-img {
  height: 100%;
  width: 85%;
  border-radius: 20px;
  padding: 5px !important;
}
.preview-container {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}

.preview-card {
  display: flex;
  border: 1px solid #a2a2a2;
  border-radius: 7px;
  padding: 5px;
}

.preview-img {
  width: 50%;
  height: 65%;
  border-radius: 5px;
  border: 1px solid white;
  background-color: white;
  margin-bottom: 10px;
}

.ps-3 {
  margin-bottom: -1.5rem;
}
.vs-input--label {
  font-size: 12px;
  color: #999999;
}
</style>
