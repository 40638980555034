<template>
    <div class="main">
      <div
        class="dropzone-container"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
        :style="isDragging && 'border-color: green; background:#f6fff8;'"
      >
        <input
          type="file"
          multiple
          name="file"
          id="fileInput"
          class="hidden-input"
          @change="onChange"
          ref="file"
          accept=".xls,.xlsx"
        />
  
        <label for="fileInput" class="file-label">
          <div v-if="isDragging">{{$t('releasedragdrop')}}</div>
          <div v-else-if="files.length">{{$t('dragdrop')}}</div>
          <div v-else>{{$t('dragdrop')}}</div>
        </label>
        <div class="preview-container mt-4" v-if="files.length">
            <div v-for="file in files" :key="file.name" class="preview-card">
            <div>
            <img class="preview-img"  src="/icons/document.svg"  /> 
            <p>
                {{ file.name }}
                {{ Math.round(file.size / 1000) + "kb" }}
                </p>
            </div>
            <div>
                <button
                class="ml-2"
                type="button"
                @click="remove(files.indexOf(file))"
                title="Remove file"
                >
                <b>X</b>
                </button>
            </div>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  window.addEventListener("load", function(event) {
    this.files.splice(0);
  });
  export default {
    data() {
      return {
        isDragging: false,
        files: [],
      };
    },
    methods: {
      open(){
        this.deleteAllFiles();
      },
      onChange() {
        const self = this;
        let incomingFiles = Array.from(this.$refs.file.files);
        const fileExist = self.files.some((r) =>
            incomingFiles.some(
            (file) => file.name === r.name && file.size === r.size
            )
        );
        if (fileExist) {
            self.showMessage = true;
            this.$vs.notify({
                color: "danger",
                position: "bottom-right",
                title: "Datei wurde bereits hochgeladen!",
            });
        } else if (self.files.length > 0) {
            self.files.splice(0);
            self.files.push(...incomingFiles);
        } else {
            self.files.push(...incomingFiles);
        }
        this.$emit('change',incomingFiles);
      },
      dragover(e) {
        // this.files.splice(0);
        e.preventDefault();
        this.isDragging = true;
      },
      dragleave() {
        this.isDragging = false;
      },
      drop(e) {
        this.files.splice(0);
        e.preventDefault();
        this.$refs.file.files = e.dataTransfer.files;
        this.onChange();
        this.isDragging = false;
      },
      deleteAllFiles() {
        this.files.splice(0);
      },
      remove(i) {
        this.files.splice(i, 1);
        this.$emit('remove');
      },
      generateURL(file) {
        let fileSrc = URL.createObjectURL(file);
        setTimeout(() => {
            URL.revokeObjectURL(fileSrc);
        }, 1000);
        return fileSrc;
      },
    },
  };
  </script>
  <style scoped src="@/assets/css/dropfile.css"></style>